import { WorkItem } from "../../components/sections/index/OurWork";

export const workPageData: WorkPageData = {
    metaPageTitle: "Our Work - iDream Interactive",
    metaKeywords: "canadian video games, windsor video games, video game library",
    metaDescription: "With over 15 years making video games, we have several casual match-3 puzzle games, slot machine games and even a couple hyper casual games. Check them out!",
    title: "Our Games",
    games: [
        {
            image: {
                name: "runebornCard",
                src: "",
                alt: "Runeborn",
            },
            url: "https://runeborn.com/",
            external: true,
        },
        {
            image: {
                name: "rrCard",
                src: "",
                alt: "Recipe Rescue",
            },
            url: "recipe-rescue"
        },
    ],
    archived: [
        {
            image: {
                name: "rrfbCard",
                src: "",
                alt: "Recipe Rescue: Fruit Blast",
            },
            url: "recipe-rescue-fruit-blast"
        },
        {
            image: {
                name: "ccCard",
                src: "",
                alt: "Crystal Crusher",
            },
            url: "crystal-crusher"
        },
        {
            image: {
                name: "booomCard",
                src: "",
                alt: "BOOOM! Casino",
            },
            url: "booom-casino"
        },
        {
            image: {
                name: "rr2Card",
                src: "",
                alt: "Recipe Rescue 2",
            },
            url: "recipe-rescue-2"
        },
        {
            image: {
                name: "htCard",
                src: "",
                alt: "Hyper Tap",
            },
            url: "hyper-tap"
        },
        {
            image: {
                name: "suCard",
                src: "",
                alt: "Slot Universe",
            },
            url: "slot-universe"
        },
        {
            image: {
                name: "sssCard",
                src: "",
                alt: "Superstar Slots",
            },
            url: "superstar-slots"
        },
        {
            image: {
                name: "sghCard",
                src: "",
                alt: "Super Gem Heroes",
            },
            url: "super-gem-heroes"
        },
    ],
}

type WorkPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    games: WorkItem[],
    archived: WorkItem[],
}