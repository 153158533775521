import React from "react";

interface Props {
    fill: string;
    className: string;
}

export const SquareIcon: React.FC<Props> = ({ fill, className }) => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill={fill} className={className}>
            <path d="M5.5,0L0,5.5L5.5,11L11,5.5L5.5,0z M5.5,6.9L4.1,5.5l1.4-1.4l1.4,1.4L5.5,6.9z" />
        </svg>
    )
}